<template>
  <v-container>
    <v-card class="elevation-2 mx-auto" :loading="loading" max-width="600">
      <v-card-title>Add User</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <form>
          <v-row>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                label="First Name"
                v-model="formdata.first_name"
                outlined
                dense
                :error-messages="form_error.first_name"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                label="Last Name"
                v-model="formdata.last_name"
                outlined
                dense
                :error-messages="form_error.last_name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                label="Email"
                v-model="formdata.email"
                outlined
                dense
                :error-messages="form_error.email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                label="Company Name"
                v-model="formdata.company_name"
                outlined
                dense
                :error-messages="form_error.company_name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                label="Phone No."
                v-model="formdata.phone_number"
                outlined
                dense
                :error-messages="form_error.phone_number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                label="Designation"
                v-model="formdata.designation"
                outlined
                dense
                :error-messages="form_error.designation"
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" md="6" sm="12" class="py-0">
              <v-select
                v-model="formdata.sponsor_type"
                :error-messages="form_error.sponsor_type"
                :items="sponsor_type"
                item-text="name"
                item-value="sponsor_id"
                label="Member Type"
                outlined
                dense
              ></v-select>
            </v-col> -->
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field
                label="LinkedIn URL"
                v-model="formdata.linkedin_url"
                outlined
                dense
                :error-messages="form_error.linkedin_url"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-select
                outlined
                dense
                :items="organization_type"
                item-text="name"
                item-value="id"
                label="Organization Type (Select all that apply)"
                :error-messages="form_error.organization_type"
                v-model="formdata.organization_type"
                multiple
              >
              </v-select>
            </v-col>
            <!-- <v-col cols="12" md="12" sm="12" class="py-0">
              <v-select
                label="Langauge Known"
                v-model="formdata.langauge_knows"
                multiple
                :items="langauge_knows"
                outlined
                dense
              >
              </v-select>
            </v-col> -->
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-file-input
                v-model="formdata.profile_pic"
                :error-messages="form_error.profile_pic"
                accept="image/*"
                placeholder="Select profile image"
                prepend-icon="mdi-file"
                outlined
                dense
              ></v-file-input>
            </v-col>

            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-btn
                class="mr-4"
                color="secondary"
                @click="addAttendee"
                :loading="loading"
                :disabled="loading"
                >submit</v-btn
              >
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
    <v-btn small color="secondary" fixed fab bottom left to="/users">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
//import axios from "@/helper/axios";
import { mapMutations } from "vuex";
export default {
  name: "AddAttendee",
  data() {
    return {
      formdata: {
        organization_type: [],
      },
      sponsor_type: [],
      form_error: {},
      organization_type: [],
      loading: false,
      langauge_knows: [
        {
          value : 'ENG',
          text : 'English'
        },
        {
          value : 'ESP',
          text : 'Spanish'
        },
        {
          value : 'VIET',
          text : 'Vietnamese'
        }
      ],
    };
  },
  created: function () {
    let _self = this;
    this.$axios
      .get("/organization/list?pagination=false")
      .then(function (response) {
        _self.organization_type = response.data.data.organizations;
      })
      .catch(function (e) {
        console.log(e);
      });
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    addAttendee() {
      let _self = this;
      this.loading = "secondary";
      let serverData = JSON.parse(JSON.stringify(this._data.formdata));
      let form_data = new FormData();
      for (let key in serverData) {
          form_data.append(key, serverData[key]);
      }
      if (this.formdata.profile_pic) {
        form_data.append("profile_pic", this.formdata.profile_pic);
      }
      this.$axios
        .post(`admin/attendee/add`, form_data)
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            _self.$router.push("/users");
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    getMemberList() {
      let _self = this;
      this.$axios
        .get(`admin/sponser_type/list?type=ATTENDEE`)
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            _self.sponsor_type = response_data.data;
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
  },
  mounted: function () {
    //this.getMemberList();
  },
};
</script>